var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-3 row"},[_c('div',{staticClass:"col-md-1"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.contenuto == 'Assicurazioni'),expression:"contenuto == 'Assicurazioni'"}],staticClass:"col-md-10"},[_c('CTabs',{attrs:{"variant":"tabs","active-tab":0}},[_c('CTab',[_c('template',{slot:"title"},[_c('i',{staticClass:"fas fa-rss fa-2x"}),_c('span',{staticClass:"tabs-nav"},[_vm._v(" Comunicazioni")])]),_c('CCardGroup',{staticClass:"latest"},_vm._l((_vm.post),function(item,index){return _c('div',{key:index,staticClass:"col-lg-3 col-sm-6 px-0 pb-0"},[_c('CCard',{staticClass:"mx-1 pt-2 card_materiale card_post pb-0 mb-0",style:({ '--bgColor': item.color_settore })},[_c('CLink',{attrs:{"to":{
                  name: 'Comunicazione',
                  params: {
                    notizia: item.titolo,
                    id: item.id,
                    lista_post: _vm.post,
                  },
                }}},[_c('CCardHeader',{staticClass:"text-uppercase pt-0 titolo_color"},[_c('strong',{staticClass:"titolo_color"},[_c('u',[_vm._v(_vm._s(item.label_settore))])])]),_c('CCardImg',{staticStyle:{"height":"15rem","object-fit":"cover"},attrs:{"src":_vm.$custom_json.base_url + item.copertina,"alt":"- IMPOSSIBILE CARICARE -"}}),(
                    _vm.lista_nuovi != null && _vm.lista_nuovi.includes(item.id_post)
                  )?_c('CBadge',{staticClass:"badgeNuovo",attrs:{"color":"danger"}},[_vm._v("Nuovo ")]):_vm._e(),_c('CCardBody',{staticClass:"py-0 px-1",staticStyle:{"min-height":"5rem"}},[_c('div',{staticClass:"text-right"},[_c('cite',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_ins)))])]),_c('h2',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.titolo,20, "[...]"))+" ")])]),_c('CCardFooter',{staticClass:"text-center py-2"},[_c('strong',[_vm._v("Leggi di più...")])])],1)],1)],1)}),0),_c('div',{staticClass:"text-center"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.post.length == 0),expression:"post.length == 0"}],staticClass:"display-4"},[_vm._v(" Nessun contenuto disponibile ")])])],2),_c('CTab',[_c('template',{slot:"title"},[_c('i',{staticClass:"fas fa-paperclip fa-2x"}),_c('span',{staticClass:"tabs-nav"},[_vm._v(" Materiale disponibile")])]),_c('PersonalCloud',{attrs:{"area":_vm.contenuto}})],2)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.contenuto == 'Energy'),expression:"contenuto == 'Energy'"}],staticClass:"col-md-10"},[_c('CTabs',{attrs:{"variant":"tabs","active-tab":0}},[_c('CTab',[_c('template',{slot:"title"},[_c('i',{staticClass:"fas fa-rss fa-2x"}),_c('span',{staticClass:"tabs-nav"},[_vm._v(" Comunicazioni")])]),_c('CCardGroup',{staticClass:"latest"},_vm._l((_vm.post),function(item,index){return _c('div',{key:index,staticClass:"col-lg-3 col-sm-6 px-0 pb-0"},[_c('CCard',{staticClass:"mx-1 pt-2 card_materiale card_post pb-0 mb-0",style:({ '--bgColor': item.color_settore })},[_c('CLink',{attrs:{"to":{
                  name: 'Comunicazione',
                  params: {
                    notizia: item.titolo,
                    id: item.id,
                    lista_post: _vm.post,
                  },
                }}},[_c('CCardHeader',{staticClass:"text-uppercase pt-0 titolo_color"},[_c('strong',{staticClass:"titolo_color"},[_c('u',[_vm._v(_vm._s(item.label_settore))])])]),_c('CCardImg',{staticStyle:{"height":"15rem","object-fit":"cover"},attrs:{"src":_vm.$custom_json.base_url + item.copertina,"alt":"- IMPOSSIBILE CARICARE -"}}),(
                    _vm.lista_nuovi != null && _vm.lista_nuovi.includes(item.id_post)
                  )?_c('CBadge',{staticClass:"badgeNuovo",attrs:{"color":"danger"}},[_vm._v("Nuovo ")]):_vm._e(),_c('CCardBody',{staticClass:"py-0 px-1",staticStyle:{"min-height":"10rem"}},[_c('div',{staticClass:"text-right"},[_c('cite',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.data_ins)))])]),_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(item.titolo))])]),_c('CCardFooter',{staticClass:"text-center py-2"},[_c('strong',[_vm._v("Leggi di più...")])])],1)],1)],1)}),0),_c('div',{staticClass:"text-center"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.post.length == 0),expression:"post.length == 0"}],staticClass:"display-4"},[_vm._v(" Nessun contenuto disponibile ")])])],2),_c('CTab',[_c('template',{slot:"title"},[_c('i',{staticClass:"fas fa-paperclip fa-2x"}),_c('span',{staticClass:"tabs-nav"},[_vm._v(" Materiale disponibile")])]),_c('PersonalCloud',{attrs:{"area":_vm.contenuto}})],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }