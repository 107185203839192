var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Archivio"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1"}),_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"breadcrumbs card_post",style:({ '--bgColor': _vm.$attrs.colore })},[_c('CLink',{staticClass:"breadcrumbs_link",on:{"click":function($event){return _vm.back()}}},[_vm._v("Gestione "+_vm._s(_vm.$route.params.settore))]),_vm._v(" > Archivio ")],1),_c('CRow',{staticClass:"justify-content-center"},[_c('h1',{staticClass:"mb-3 titolo_sezione"},[_vm._v("Archivio")])]),_c('CCardBody',{staticClass:"mt-2",attrs:{"color":"white"}},[_c('CDataTable',{ref:"tabella_post",attrs:{"noItemsView":{
            noResults: 'no filtering results available custom',
            noItems: 'Nessuna comunicazione o materiale presente',
          },"items":_vm.post,"fields":_vm.fields,"table-filter":{
            placeholder: 'Ricerca...',
            label: 'Ricerca contenuti:',
          },"items-per-page-select":{ label: 'Risultati per pagina' },"items-per-page":_vm.post_per_pagina,"hover":"","sorter":"","pagination":"","striped":"","clickableRows":""},on:{"row-clicked":_vm.myRowClickHandler},scopedSlots:_vm._u([{key:"show_details",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"py-2",staticStyle:{"text-align":"center !important"}},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onToggleDetailsClicked(item.id)}}},[_vm._v(" "+_vm._s(Boolean(item._toggled) ? "Nascondi" : "Mostra")+" ")]),_c('router-link',{attrs:{"to":{
                  name: 'ModificaContenuti',
                  params: {
                    post: item.id,
                    editPost: item,
                    settore: _vm.$attrs.settore,
                    lista_aree: _vm.$attrs.lista_aree,
                  },
                }}},[_c('CButton',{staticClass:"ml-2",attrs:{"id":'ModificaPost' + item.id,"size":"sm","color":"warning"}},[_vm._v(" Modifica ")])],1)],1)]}},{key:"details",fn:function(ref){
                var item = ref.item;
return [_c('CCollapse',{attrs:{"show":item._toggled,"duration":_vm.collapseDuration}},[_c('CCardBody',[_c('CMedia',{attrs:{"aside-image-props":{
                    src: _vm.$custom_json.base_url + item.copertina,
                  }}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[_c('div',[_c('span',{staticClass:"text-muted"},[_c('h4',[_vm._v("Contenuto:")])]),_c('p',{domProps:{"innerHTML":_vm._s(item.contenuto)}})])]),_c('div',{staticClass:"col-sm-4"},[_c('h4',[_vm._v("Permessi:")]),_c('treeselect',{attrs:{"multiple":true,"always-open":false,"disabled":true,"options":_vm.options,"max-height":300,"placeholder":"Permessi","disableFuzzyMatching":true},model:{value:(item.permessi),callback:function ($$v) {_vm.$set(item, "permessi", $$v)},expression:"item.permessi"}}),_c('hr',{directives:[{name:"show",rawName:"v-show",value:(item.allegati == 1),expression:"item.allegati == 1"}]}),_c('h4',{directives:[{name:"show",rawName:"v-show",value:(item.allegati == 1),expression:"item.allegati == 1"}]},[_vm._v(" Allegati presenti: "+_vm._s(item.files.length)+" ")])],1)])])],1)],1)]}}])})],1)],1),_c('div',{staticClass:"col-md-1"})])])}
var staticRenderFns = []

export { render, staticRenderFns }