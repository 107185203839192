var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background-color":"white"}},[_c('div',{staticClass:"copertina"},[_c('CImg',{attrs:{"src":_vm.$custom_json.base_url + _vm.lista_post[_vm.id].copertina}}),_c('section',{staticClass:"box-titolo"},[_c('div',{staticClass:"area"},[_c('CLink',{staticClass:"area",style:({ '--bgColor': _vm.lista_post[_vm.id].color_settore }),attrs:{"to":'../../Commerciale/' + _vm.lista_post[_vm.id].settore}},[_vm._v(_vm._s(_vm.lista_post[_vm.id].label_settore)+" ")])],1),_c('div',{staticClass:"data"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.lista_post[_vm.id].data_ins)))]),_c('div',{staticClass:"titolo"},[_vm._v(_vm._s(_vm.lista_post[_vm.id].titolo))]),_c('div',{staticClass:"sottotitolo"},[_vm._v(" "+_vm._s(_vm.lista_post[_vm.id].categoria)+" - "+_vm._s(_vm.lista_post[_vm.id].sottotitolo)+" ")])])],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"contenuto"},[_c('div',{staticClass:"text-ellipsis-2l text-justify",domProps:{"innerHTML":_vm._s(_vm.lista_post[_vm.id].contenuto)}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_post[_vm.id].allegati && _vm.lista_post[_vm.id].files.length > 0),expression:"lista_post[id].allegati && lista_post[id].files.length > 0"}],staticClass:"allegati"},[_c('i',{staticClass:"text-muted fas fa-paperclip fa-2x"}),_c('span',{staticClass:"text-muted h4 ml-3"},[_vm._v("Documenti allegati:")]),_c('hr',{staticStyle:{"width":"30%","text-align":"left","margin-left":"0","border":"2px solid"}}),_c('div',{staticClass:"listaFile text-center"},_vm._l((_vm.lista_post[_vm.id].files),function(allegato){return _c('div',{key:allegato.nome_file,staticClass:"file"},[_c('div',{staticClass:"cloud",on:{"click":function($event){(_vm.showDoc = true),
                (_vm.file =
                  _vm.$custom_json.base_url +
                  allegato.percorso_file +
                  '/' +
                  allegato.nome_file),
                (_vm.ext = allegato.ext_file.toUpperCase()),
                (_vm.nome_file = allegato.nome_file)}}},[_c('div',{class:allegato.ext_file.toUpperCase()},[_c('div',{staticClass:"desc_elemento mx-3"},[_vm._v(" "+_vm._s(allegato.nome_file)+" ")])])])])}),0)])]),_c('Visualizzatore',{attrs:{"showDoc":_vm.showDoc,"file":_vm.file,"ext":_vm.ext,"nome_file":_vm.nome_file},on:{"aggiorna_modale":_vm.aggiorna_modale}}),_c('div',{staticClass:"container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_post.length > 1),expression:"lista_post.length > 1"}],staticClass:"navigazione"},[_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 text-right navigazione-col"},[(_vm.lista_post[_vm.id].id < _vm.last)?_c('span',{staticClass:"text-muted indicatore",staticStyle:{"font-size":"1rem"}},[_vm._v("Precedente "),_c('br')]):_vm._e(),(_vm.lista_post[_vm.id].id < _vm.last)?_c('span',{staticClass:"prev text-muted h6"},[_c('CLink',{attrs:{"to":{
                name: 'Comunicazione',
                params: {
                  notizia: _vm.lista_post[_vm.lista_post[_vm.id].id + 1].titolo,
                  id: _vm.lista_post[_vm.lista_post[_vm.id].id + 1].id,
                  lista_post: _vm.lista_post,
                },
              }}},[(_vm.lista_post[_vm.id].id < _vm.last)?_c('em',[_vm._v(_vm._s(_vm.lista_post[_vm.lista_post[_vm.id].id + 1].titolo))]):_vm._e()])],1):_vm._e()]),_c('div',{staticClass:"col-6"},[(_vm.lista_post[_vm.id].id != 0)?_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"1rem"}},[_vm._v("Successiva "),_c('br')]):_vm._e(),(_vm.lista_post[_vm.id].id != 0)?_c('span',{staticClass:"next text-muted h6"},[_c('CLink',{attrs:{"to":{
                name: 'Comunicazione',
                params: {
                  notizia: _vm.lista_post[_vm.lista_post[_vm.id].id - 1].titolo,
                  id: _vm.lista_post[_vm.lista_post[_vm.id].id - 1].id,
                  lista_post: _vm.lista_post,
                },
              }}},[(_vm.lista_post[_vm.id].id != 0)?_c('em',[_vm._v(_vm._s(_vm.lista_post[_vm.lista_post[_vm.id].id - 1].titolo))]):_vm._e()])],1):_vm._e()])]),_c('hr')])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }